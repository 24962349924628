<template>
  <!-- 项目详情 -->
  <div style="padding-top: 77px;">
    <div class="project-detail">
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>{{title}}</span>
          <el-button style="float: right;" type="primary" @click="dialogVisible = true;">回 答</el-button>
          <el-button class="goback" type="text" @click="goback">返回 ></el-button>
        </div>
        <div class="docs">
          <span>回答列表：</span>
          <div class="docs-item" v-for="item in results" :key="item.content">
            <span>
              <img src="imgs/icon-decoration.png" alt="">
              <span>{{item.content}}</span>
            </span>
            <span>
              <span>用户：{{item.userName}} </span>
              <span>时间：{{item.createTime}}</span>
            </span>
          </div>
        </div>
        <div class="pagenation">
          <el-pagination
            background
            layout="total, slot, prev, pager, next, slot"
            :current-page="currentPage"
            :page-size="pageSize"
            :total="total"
            @current-change="currentChange">
          </el-pagination>
        </div>
      </el-card>
    </div>

    <el-dialog
      title="提交回答"
      :visible.sync="dialogVisible"
      width="30%"
      top="30vh">
      <el-form ref="form" :model="form" label-width="100px">
        <el-form-item label="回答内容：">
          <el-input v-model="form.content"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getStore } from '../../js/utils/store'
import { mapGetters } from 'vuex'
import { getResults, resolveQues } from '../../api/aboutUs'
export default {
  data() {
    return {
      results: [],
      dialogVisible: false,
      form: {},
      currentPage: 1,
      pageSize: 10,
      total: 0,
      title: ''
    }
  },
  created() {
    this.initData();
  },
  computed: mapGetters(['userInfo',]),
  methods: {
    goback() {
      this.$router.go(-1);
    },
    async initData() {
      // 获取回答列表
      let params = {
        askid: this.$route.query.c,
        current: this.currentPage,
        size: this.pageSize
      }
      let res = await getResults(params);
      let {code, data} = res;
      if(code == 0) {
        this.results = data.page.records;
        console.log(this.results, 'results')
        this.title = data.askContent;
        this.total = data.total;
      }
    },
    currentChange(val) {
      this.currentPage = val;
      this.initData();
    },
    joinProject() {
      // 立即报名
      let token = getStore({name: 'access_token'});
      if(token) {
        // 报名
        this.dialogVisible = true;
      } else {
        this.$confirm('您需要登陆才可以报名，是否跳转到登陆页?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          localStorage.setItem('backPath', this.$route.fullPath)
          this.$router.push({name: 'login'})
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });
        });
      }
    },
    dialogClose() {
      // 关闭弹窗
      this.dialogVisible = false;
    },
    async submit() {
      // 提交回答
      let info = {
        username: this.userInfo.userName,
        askid: this.$route.query.c,
        content: this.form.content
      }
      let res = await resolveQues(info);
      let {code,data,msg } = res;
      if(code == 0) {
        this.$message.success('提交回答成功');
        this.dialogVisible = false;
        this.initData();
      } else {
        this.$message.error(msg);
      }
    }
  }
}
</script>

<style lang="less" scoped>
.project-detail {
  width: 1200px;
  margin: 0 auto;
  background: #fff;
  text-align: left;
  padding-bottom: 128px;
  padding-top: 50px;
  .goback {
    margin-right: 10px;
    color:  #00a0e9;
    cursor: pointer;
  }
  .box-card {
    width: 90%;
    margin: 0 auto;
  }
  .docs {
    // height: 640px;
    margin-bottom: 8px;
    // overflow-y: auto;
    span {
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .docs-item {
      height: 56px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      line-height: 56px;
      color: #333333;
      padding-right: 8px;
      margin-bottom: 14px;
      border-bottom: 1px solid #c9c9c9;
      img {
        margin-right: 9px;
      }
    }
  }
}
</style>
