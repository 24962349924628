// 关于我们
import service from '../request'
const baseUrl = window.globalUrl.HOME_API;

// 获取问题列表
export const getQuestions = (params) => {
  return service({
    method: 'get',
    url: `${baseUrl}/admin/ask/list`,
    params: params
  })
}

// 用户提问
export const reportQuestion = (params) => {
  return service({
    method: 'post',
    url: `${baseUrl}/admin/ask/doask`,
    data: params
  })
}

// 回答
export const resolveQues = (params) => {
  return service({
    method: 'post',
    url: `${baseUrl}/admin/answer/doanswer`,
    data: params
  })
}

// 获取回答列表
export const getResults = (params) => {
  return service({
    method: 'get',
    url: `${baseUrl}/admin/answer/list`,
    params: params
  })
}

// 获取问题详情
export const getQuestionDetail = (params) => {

  return service({
    method: 'get',
    url: `${baseUrl}/admin/answer/list`,
    params: params
  })
}
